<template>
	<div>
		<el-button @click="getWatchkeeper" size="small">刷新</el-button>
		<el-table :data="tableData" v-loading="tableLoading" border stripe
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
			style="width: 100%;">
			<el-table-column label="#" type="index" width="60"></el-table-column>
			<el-table-column label="开始时间" prop="shift_time"></el-table-column>
			<el-table-column label="结束时间" prop="end_time"></el-table-column>
			<el-table-column label="值班位置">
				<template v-slot="{ row }">
          <div v-if="row.building">
            <span>{{ row.building.area.name }}/{{ row.building.name }}</span>
          </div>
				</template>
			</el-table-column>
			<el-table-column label="值班人" prop="adminer"></el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {
		getWatchkeeperAPI
	} from '@/api/outpatient.js'

	export default {
		name: 'OutpatientWatchkeeper',
		data() {
			return {
				tableData: [],
				tableLoading: false,
			}
		},
		created() {
			this.getWatchkeeper()
		},
		methods: {

			getWatchkeeper() {
				this.tableLoading = true

				getWatchkeeperAPI().then(res => {
					this.tableData = res
					this.tableLoading = false
				})
			},
		}
	}
</script>

<style>
</style>